<template>
  <div class="home">
    <div class="appcs">
      <img class="img1" src="../assets/yijian.png" alt="" />
      <img class="img2" src="../assets/tuceng.png" alt="" />
      <img
        class="img3"
        @click="open"
        src="../assets/zhongjiangjilu.png"
        alt=""
      />
      <img class="img4" src="../assets/kailihe.png" alt="" />
      <div class="box1">
        <div class="inp1">
          <van-field v-model="name" placeholder="请输入您的姓名" />
        </div>
        <div class="inp2">
          <van-field v-model="phone" placeholder="请输入您的手机号" />
        </div>
        <span class="title">您对以下那些检测感兴趣？（可多选）</span>
        <div class="jiance">
          <van-checkbox-group v-model="checkedValues" @change="onChange">
            <van-checkbox
              name="儿童天赋基因检测(体质与行为天性）"
              shape="square"
              ><span class="name"
                >儿童天赋基因检测(体质与行为天性）</span
              ></van-checkbox
            >
            <van-checkbox
              name="儿童安全成长基因检测（用药安全与营养代谢）"
              shape="square"
              ><span class="name"
                >儿童安全成长基因检测（用药安全与营养代谢）</span
              ></van-checkbox
            >
            <van-checkbox name="成人相关体质基因检测" shape="square"
              ><span class="name"> 成人相关体质基因检测</span></van-checkbox
            >
            <van-checkbox name="WES全外显子遗传筛查检测" shape="square"
              ><span class="name">WES全外显子遗传筛查检测</span></van-checkbox
            >
            <van-checkbox name="以上都不感兴趣" shape="square"
              ><span class="name">以上都不感兴趣</span></van-checkbox
            >
          </van-checkbox-group>
        </div>
      </div>
      <div class="choujiangbug">
        <!-- 转盘包裹 -->
        <div class="rotate">
          <!-- 绘制圆点 -->
          <div
            :class="'circle circle_' + index"
            v-for="(item, index) in circleList"
            :key="index"
            :style="{
              background: index % 2 == 0 ? colorCircleFirst : colorCircleSecond,
            }"
          ></div>
          <!-- 转盘图片 -->
          <img
            class="dish"
            src="../assets/zhuanpan1.png"
            :style="{ transform: rotate_deg, transition: rotate_transition }"
          />
          <!-- 指针图片 -->
          <img class="pointer" src="../assets/zhizhen.png" />
        </div>

        <img class="zhuzi" src="../assets/zhuzi.png" alt="" />
        <div class="but" @click="start"></div>
        <div class="tit">
          <div class="huodong">活动说明</div>
          <div class="text">
            幸运大转盘，奖品丰富，参与即有机会赢取精美礼品，快来一试手气，奖品领完截止！
            本次活动最终解释权归主办方所有。
          </div>
        </div>
      </div>
    </div>
    <van-overlay style="z-index: 99" :show="show">
      <div class="overlay">
        <div class="biankuang">
          <div class="box1">我的奖品</div>
          <div class="box2">
            <img
              class="liwu"
              v-if="tabList[0]"
              src="../assets/liwu.png"
              alt=""
            />
            <div class="jiangpin" v-if="tabList[0]">
              {{ tabList[0].awards || "" }}
            </div>
          </div>
          <div class="box3">
            <div class="time" v-if="tabList[0]">
              {{ tabList[0].createTime || "" }}
            </div>
          </div>
        </div>
        <img class="x" src="../assets/x.png" alt="" @click="colose" />
      </div>
    </van-overlay>
  </div>
</template>

<script>
// import wx from 'weixin-js-sdk'
export default {
  name: "Home",
  data() {
    return {
      meiki: "",
      appID: "wxd7b106df20a04a05",
      code: "",
      openId: "",
      phone: "",
      name: "",
      checkedValues: [],
      LuckyClick: 1,
      circleList: [], //原点设置
      colorCircleFirst: "#FE4D32", //圆点颜色
      colorCircleSecond: "#fff", //圆点颜色

      cat: 45, //总共8个扇形区域，每个区域约45度
      isAllowClick: true, //是否能够点击
      rotate_deg: 0, //指针旋转的角度
      rotate_transition: "transform 3s ease-in-out", //初始化选中的过度属性控制
      show: false,
      tabList: [],
      indexValue: null,
    };
  },
  created() {
    this.showcircleList();
  },
  mounted() {
    this.code = this.$route.query.code;
    console.log(this.$route.query, "路由");
    if (!this.code) {
      this.getexcellent();
      // this.userPay()
      console.log(this.code);
    } else {
      this.getcode();
    }
  },
  methods: {
    onChange() {
      console.log(this.checkedValues);
    },
    open() {
      this.api
        .list({
          openid: this.openId,
        })
        .then((res) => {
          this.tabList = res.data;
          this.show = true;
        });
      this.show = true;
    },
    colose() {
      this.show = false;
    },
    getexcellent() {
      if (!this.code) {
        const url = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${
          this.appID
        }&redirect_uri=${encodeURIComponent(
          `https://draw.yjmedlab.com`
        )}&response_type=code&scope=snsapi_base&state=123#wechat_redirect`;
        location.href = url;
        return;
      }
    },
    getcode() {
      this.api.SmsCode(this.code).then((res) => {
        console.log(res);
        this.openId = res.data;
      });
    },
    //绘制圆点设置
    showcircleList() {
      let circleList = [];
      for (var i = 0; i < 16; i++) {
        circleList.push(i);
      }
      this.circleList = circleList;
      // this.light()
    },

    start() {
      if (this.name == "") {
        return alert("请填写您姓名");
      }
      if (this.phone == "") {
        return alert("请填写您的手机号");
      }
      let reg = /^1[3|4|5|6|7|8|9][0-9]\d{8}$/;
      if (!reg.test(this.phone)) {
        return alert("请填写正确的手机号");
      }
      let content = "";
      if (this.checkedValues.length) {
        content = this.checkedValues.join(", ");
      }

      this.api
        .add({
          openid: this.openId,
          name: this.name,
          phone: this.phone,
          content: content,
        })
        .then((res) => {
          console.log(res);
          if (res.code == 200) {
            this.indexValue = Number(res.data);
            this.rotating();
          }
          if (res.code == 500) {
            alert("您已参与过本次抽奖，不能再次参与!");
            return;
          }
        });
    },

    rotating() {
      if (!this.isAllowClick) return;
      this.isAllowClick = false;
      this.rotate_transition = "transform 3s ease-in-out";
      this.LuckyClick--;
      var rand_circle = 5; //默认多旋转5圈
      //   var winningIndex = Math.floor(Math.random() * 8); //获奖的下标   0-7   没有概率每个平均
      var winningIndex = this.set(); //设置了概率的

      console.log(winningIndex);
      var randomDeg = 360 - winningIndex * 45; //当前下标对应的角度    45是总共8个扇形区域，每个区域约45度

      var deg = rand_circle * 360 + randomDeg; //将要旋转的度数  由于是顺时针的转动方向需要用360度来减
      this.rotate_deg = "rotate(" + deg + "deg)";

      var that = this;
      setTimeout(function () {
        that.isAllowClick = true;
        that.rotate_deg = "rotate(" + 0 + "deg)"; //定时器关闭的时候重置角度
        that.rotate_transition = "";

        if (winningIndex == 0) {
          alert("恭喜您，获得一等奖");
        } else if (winningIndex == 2) {
          alert("恭喜您，获得二等奖");
        } else if (winningIndex == 4) {
          alert("恭喜您，获得三等奖");
        } else if (winningIndex == 6) {
          alert("恭喜您，获得四等奖");
        }
      }, 3500);
    },

    //设置概率
    set() {
      var winIndex;
      //方法1
      //   if (Math.floor(Math.random() * 100) < 30) {
      //     console.log("30%的概率,重在参与");
      //     winIndex = 2;
      //   } else if (Math.floor(Math.random() * 100) < 55) {
      //     console.log("25%的概率,5元");
      //     winIndex = 6;
      //   } else if (Math.floor(Math.random() * 100) < 75) {
      //     console.log("20%的概率,10元");
      //     winIndex = 1;
      //   } else if (Math.floor(Math.random() * 100) < 85) {
      //     console.log("10%的概率,20元");
      //     winIndex = 4;
      //   } else if (Math.floor(Math.random() * 100) < 92) {
      //     console.log("7%的概率,30元");
      //     winIndex = 3;
      //   } else if (Math.floor(Math.random() * 100) < 97) {
      //     console.log("5%的概率,50元");
      //     winIndex = 5;
      //   } else if (Math.floor(Math.random() * 100) < 99) {
      //     console.log("2%的概率,100元");
      //     winIndex = 7;
      //   } else if (Math.floor(Math.random() * 100) == 99) {
      //     console.log("1%的概率,IphoneX");
      //     winIndex = 0;
      //   }

      //方法2
      // var __rand__ = Math.random()
      winIndex = this.indexValue;

      return winIndex;
    },
  },
};
</script>
<style lang="less" scoped>
.home {
  width: 100%;
  height: 100vh;
  overflow: scroll;
  /* border: 1px solid red; */
  .appcs {
    height: 1468px;
    width: 100%;
    background: url("../assets/bg@2x.png");
    background-size: 100% 100%;
    position: relative;
    .img1 {
      width: 136px;
      height: 29px;
      margin-top: 27px;
      margin-left: 30px;
    }
    .img2 {
      width: 266px;
      height: 115px;
      margin-top: 14px;
      margin-left: 17px;
    }
    .img3 {
      position: fixed;
      top: 36px;
      right: 0;
      width: 84px;
      height: 35px;
      z-index: 9999;
    }
    .img4 {
      position: absolute;
      top: 130px;
      right: 0;
      width: 129px;
      height: 107px;
    }
    .box1 {
      width: 353px;
      height: 430px;
      margin-left: 11px;
      background: url("../assets/xinxi.png");
      background-size: 100% 100%;
      overflow: hidden;
      .inp1 {
        margin-left: 25px;
        margin-top: 72px;
        width: 298px;
        height: 45px;
        background: #ffffff;
        border-radius: 24px;
        border: 1px solid #979797;
        .van-cell {
          margin-top: 2px;
          border-radius: 25px;
          line-height: 30px;
          font-size: 16px !important;
          height: 44px;
        }
      }
      .inp2 {
        margin-left: 25px;
        margin-top: 20px;
        width: 298px;
        height: 45px;
        background: #ffffff;
        border-radius: 24px;
        border: 1px solid #979797;
        .van-cell {
          margin-top: 2px;
          border-radius: 25px;
          line-height: 30px;
          font-size: 16px !important;
          height: 44px;
        }
      }
      .title {
        margin-left: 29px;
        margin-top: 10px;
        height: 20px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        font-size: 14.5px;
        color: #616161;
        line-height: 20px;
        text-align: left;
        font-style: normal;
      }
      .jiance {
        margin-left: 30px;

        .name {
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          font-size: 14px;
          color: #616161;
          line-height: 38px;
          text-align: left;
          font-style: normal;
        }
      }
    }
    .choujiangbug {
      margin-top: 18px;
      width: 375px;
      height: 733px;
      background: url("../assets/zuanbanbg.png");
      background-size: 100% 100%;
      position: relative;
      .tit {
        width: 297px;
        height: 116px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 300;
        font-size: 13px;
        color: #ffffff;
        line-height: 18px;
        text-align: left;
        font-style: normal;
        position: absolute;
        bottom: -20px;
        left: 50%;
        transform: translate(-50%, -50%);
        .huodong {
          width: 100%;
          text-align: center;
          font-family: YouSheBiaoTiYuan;
          font-size: 20px;
          color: #ffffff;
          line-height: 26px;
          font-style: normal;
          letter-spacing: 0px;
          font-weight: 600;
        }
        .text {
          margin-top: 13px;
          font-family: PingFangSC, PingFang SC;
          font-weight: 300;
          font-size: 13px;
          color: #ffffff;
          line-height: 21px;
          text-align: left;
          font-style: normal;
        }
      }
      .zhuzi {
        width: 255px;
        height: 120px;
        position: absolute;
        top: 435px;
        left: 50%;
        transform: translate(-50%, -50%);
      }
      .but {
        width: 289px;
        height: 67px;
        position: absolute;
        top: 495px;
        left: 50%;
        background: url("../assets/but.png");
        background-size: 100% 100%;
        transform: translate(-50%, -50%);
      }
      .rotate {
        width: 293px;
        height: 293px;
        background: #ffbe04;
        border-radius: 50%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 300px;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 1;
      }

      .rotate .dish {
        width: 293px;
        height: 293px;
      }

      .pointer {
        width: 1.39rem;
        height: 1.75rem;
        position: absolute;
        top: 48%;
        left: 50%;
        transform: translate(-50%, -50%);
      }

      /* 圆点 */
      .rotate .circle {
        position: absolute;
        display: block;
        border-radius: 50%;
        height: 0.16rem;
        width: 0.16rem;
        background: black;
      }
    }
  }
  .overlay {
    margin: auto;
    margin-top: 60%;
    width: 340px;
    height: 165px;
    background: url("../assets/jilu.png");
    background-size: 100% 100%;
    border-radius: 15px;
    position: relative;
    .x {
      position: absolute;
      height: 33px;
      width: 33px;
      top: 212px;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 4;
    }
    .biankuang {
      float: left;
      margin-left: 32px;
      margin-top: 52px;

      width: 274px;
      height: 73px;
      background: rgba(216, 216, 216, 0);
      border: 1px solid #979797;
      display: flex;
      .box1 {
        width: 30%;
        height: 100%;
        border-right: 1px solid #979797;
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        font-size: 13px;
        color: #616161;
        line-height: 73px;
        text-align: center;
        font-style: normal;
      }
      .box2 {
        width: 40%;
        height: 100%;
        border-right: 1px solid #979797;
        display: flex;
        .liwu {
          width: 24px;
          height: 25px;
          margin-top: 24px;
          margin-left: 15px;
        }
        .jiangpin {
          margin-left: 10px;
          font-family: PingFangSC, PingFang SC;
          font-weight: 500;
          font-size: 13px;
          color: #616161;
          line-height: 73px;
          text-align: left;
          font-style: normal;
        }
      }
      .box3 {
        width: 40%;
        height: 100%;
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        font-size: 13px;
        color: #616161;
        font-style: normal;
        .time {
          margin: 0 auto;
          width: 80%;
          margin-top: 18px;
        }
      }
    }
  }
}
</style>
